export const STORAGE_LANG_KEY = 'preferred_lang';

export const STORAGE_THEME_KEY = 'theme';

export const STORAGE_LIGHT_THEME_VALUE = 'light';

export const STORAGE_DARK_THEME_VALUE = 'light'; // Todo: replace with 'dark'

export const LIGHT_THEME_CLASSNAME = 'lightTheme';

export const DARK_THEME_CLASSNAME = 'lightTheme'; // Todo: replace with 'darkTheme'

export const CURRENCY_MAP = {
  uk: {
    sign: '₴',
    bankId: 'UAH',
  },
  en: {
    sign: '$',
    bankId: 'USD',
  },
};

export const AMOUNT_LIST = [
  {
    value: 10,
    title: 10,
  },
  {
    value: 50,
    title: 50,
  },
  {
    value: 100,
    title: 100,
  },
  {
    value: 1000,
    title: 1000,
  },
  {
    value: '',
    title: 'Other',
  },
];
