import { createGlobalStyle } from 'styled-components';

import { easeOutTiming } from './animation';

// media queries
export const breakpoints = {
  mobile: '480px',
  mobileM: '660px',
  tabletS: '980px',
  tablet: '1024px',
  laptop: '1440px',
  desktop: '1920px',
};

export const GlobalStyle = createGlobalStyle`
  :root {
    --base-font: Roboto, sans-serif;

    --weight-light: 300;
    --weight-regular: 400;
    --weight-medium: 500;
    --weight-bold: 700;

    // colors
    --primary-color: #0075ff;
    --primary-color-06: rgba(0, 117, 255, .06);
    --primary-color-10: rgba(0, 117, 255, .10);
    --primary-color-20: rgba(0, 117, 255, .20);

    --secondary-color: #ffc700;
    
    --color-white: #fff;
    --color-white-02: rgba(255, 255, 255, .02);
    --color-white-03: rgba(255, 255, 255, .03);
    --color-white-05: rgba(255, 255, 255, .05);
    --color-white-06: rgba(255, 255, 255, .06);
    --color-white-10: rgba(255, 255, 255, .10);
    --color-white-30: rgba(255, 255, 255, .30);
    --color-white-50: rgba(255, 255, 255, .50);
    --color-white-60: rgba(255, 255, 255, .60);
    --color-white-90: rgba(255, 255, 255, .90);

    --color-black-02: rgba(0, 0, 0, .02);
    --color-black-03: rgba(0, 0, 0, .03);
    --color-black-05: rgba(0, 0, 0, .05);
    --color-black-06: rgba(0, 0, 0, .06);
    --color-black-10: rgba(0, 0, 0, .10);
    --color-black-25: rgba(0, 0, 0, .25);
    --color-black-30: rgba(0, 0, 0, .30);
    --color-black-50: rgba(0, 0, 0, .50);
    --color-black-60: rgba(0, 0, 0, .60);
    --color-black-90: rgba(0, 0, 0, .90);

    --color-red-05: rgba(255, 0, 0, .05);

    --success-color: #65B923;
    --error-color: #e51212;

    // buttons
    --primary-button-color: var(--primary-color);
    --primary-button-hover: #006ae6;
    --primary-button-active: #005ecc;

    // animation
    --transition-duration: .3s;
    --timing-function: ease-out;

    // variables
    --gap: 2rem;
    --gap-mobile: 1rem;

    --border: .0625rem;
    --border-radius: 0.25rem;
    --box-radius: 0.375rem;
    --card-radius: .75rem;

    --container-padding: 4rem;
    --container-padding-tablet: 2rem;
    --container-padding-mobile: 1rem;

    --z-index-tooltip: -1;
    
    --z-index-header: 2;
    --header-height: 4.25rem;
    --header-height-mobile: 3.75rem;
    
    --progress-bar-height: 0.125rem;
    --box-shadow: 0 .25rem 1.5rem 0 var(--color-black-25);

    --form-width: 27.5rem;
    --text-container-width: 35rem;
    } 

    .lightTheme {
      --base-background-color: var(--color-white);

      --border-color-05: var(--color-black-05);
      --border-color-10: var(--color-black-10);

      --header-background-color: var(--base-background-color);

      --footer-background-color: var(--color-black-03);

      --primary-text-color: var(--color-black-50);
      --secondary-text-color: var(--color-black-90);

      --button-text-color: var(--color-black-60);

      --primary-button-background-color: var(--color-black-03);
      --secondary-button-background-color: var(--color-black-10);

      --form-background-color: var(--color-black-02);

      --input-border-color-active: var(--color-black-30);
      --input-background-color-active: var(--color-black-06);
    }

    .darkTheme {
      --base-background-color: #181b22;

      --border-color-05: var(--color-white-05);
      --border-color-10: var(--color-white-10);

      --header-background-color: var(--base-background-color);
      
      --footer-background-color: var(--color-white-03);

      --primary-text-color: var(--color-white-50);
      --secondary-text-color: var(--color-white-90);

      --button-text-color: var(--color-white-60);

      --primary-button-background-color: var(--color-white-03);
      --secondary-button-background-color: var(--color-white-10);

      --form-background-color: var(--color-white-02);
      
      --input-border-color-active: var(--color-white-30);
      --input-background-color-active: var(--color-white-06);
    }

    ::selection {
      color: var(--primary-color);
      background: var(--secondary-color);
    }

    * {
      -webkit-tap-highlight-color: transparent;
    }

    *,
    *::after,
    *::before {
      box-sizing: border-box;
    }

    html {
      font-size: 100%;
      line-height: 1.5;
      font-family: var(--base-font);
      background-color: var(--base-background-color);
      transition: background-color ${easeOutTiming};
      scroll-behavior: smooth;
      padding: 0;
      margin: 0;
    }

    [dir=rtl] {
      font-size: 110%;
    }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: var(--weight-regular);
      color: var(--primary-text-color);
      margin: 0;
    }

    p,
    h1,
    h2,
    h3 {
      margin: 0;
      padding: 0;
    }

    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    button {
      margin: 0;
      padding: 0;
      border: none;
      cursor: pointer;
      appearance: none;
      background: none;
    }

    input,
    button,
    select,
    textarea {
      font-family: inherit;
      line-height: 1.15;
      font-size: 100%;
      margin: 0;
    }

    svg {
      display: -webkit-box;
    }

    a { 
      text-decoration: none;
      color: var(--primary-text-color);
      transition: color ${easeOutTiming};
    
      @media (hover: hover) {
        &:hover {
          color: var(--secondary-text-color);
        }
      }

      &.activeClassLink {
        color: var(--secondary-text-color);
        text-decoration: underline;
        cursor: default;
      }
    }

    b,
    strong {
      font-weight: var(--weight-bold);
    }
`;
